/**
 * Manager to handle the fetching and storage of 'Here' Geocaching data
 * takes an address, returns a queried address containing Geo Coordinates
 *
 * This will be stored in cache for future use, and reduction of requests to Here
 */

// NOTE: if this throws an import error, add "browser": { "fs": false } to 'package.json' (webpack bug)
// import FILE_SYSTEM from 'fs'
// const FILESYS = require('fs')
import axios from "axios"
import moment from "moment"
/**
 * should automatically pull data from the server cache
 *
 * and if the 'last update' is older than a time limit, signal the google cache to run a check
 */

// number of minutes
let CACHE_NUM_MINUTES_TO_REFRESH = 10

class MANAGER {
    OBJECTS = {}

    constructor() {
        // console.log("Created new Caching manager")

        this._DATA = null

        // axios
        //     .get("/cache/ping")
        //     .then((res) => {
        //         console.log("CacheAPI: backend is running!")
        //     })
        //     .catch((err) => {
        //         console.warn(
        //             "CacheAPI: BACKEND NOT RUNNING! caching functionality will not work in this session!"
        //         )
        //         throw new Error(err)
        //         console.log(err)
        //     })
    }

    fetchData() {}

    latestRequest() {}

    async loadFileData() {
        // let rawData = FILE_SYSTEM.readFileSync('assets/data/GeoCodingCache.json')
        // let rawData = FILESYS.readFile('GeoCodingCache.json')
        // let cache = JSON.parse(rawData)

        await axios
            .get("/cache/get")
            .then((res) => {
                // console.log("CLIENT: request made")

                console.log("CacheAPI: Loaded successfully")
                this._DATA = res.data
            })
            .catch((err) => {
                console.log(err)
            })
        // console.log(cache)

        // return cache
    }

    storeFileData(data) {
        console.log("CacheAPI: Updating Cache ...")
        axios
            .post("/cache/post", { data })
            .then((res) => {
                // console.log("CLIENT: Post request made")
                console.log("CacheAPI: Cache Updated successfully")
                // console.log(res)
                this._DATA = data
            })
            .catch((err) => {
                console.log(err)
            })
    }

    get getData() {
        return this._DATA
    }

    get requiresSync() {
        let last_sync = new Date(this.getData.LAST_UPDATE)
        let ten_min_from_last = moment(last_sync)
            .add(CACHE_NUM_MINUTES_TO_REFRESH, "m")
            .toDate()
        // console.log(this.getData)
        // console.log(last_sync)
        // console.log(ten_min_from_last)
        // console.log(new Date())

        return !this.getData || ten_min_from_last < new Date()
    }
}

export default MANAGER
